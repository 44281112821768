<template>
  <div class="container-middle header__container-middle">
    <HeaderLinkNav :to="{ name: ROUTE_LIST_NANNY_NAME }" :label="'Szukaj...'" />
  </div>
</template>

<script>
import { ROUTE_LIST_NANNY_NAME } from "@/router/constants";
import HeaderLinkNav from "@/components/Header/HeaderLinkNav.vue";

export default {
  components: {
    HeaderLinkNav,
  },

  setup() {
    return {
      ROUTE_LIST_NANNY_NAME,
    };
  },
};
</script>
