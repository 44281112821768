import { getRawAsyncComponent } from "@/helpers/getRawAsyncComponent";
import {
  ROUTE_ACCOUNT_APPLICATIONS_NAME,
  ROUTE_ACCOUNT_OFFERS_NAME,
  ROUTE_ACCOUNT_PREMIUM_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_NAME,
  ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME,
} from "@/router/constants";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";

const resolveImport = (iconName) => {
  return import("@/components/Notification/" + iconName);
};

class Notification {
  constructor({ name, toRouteName, title, description, iconComponentName }) {
    this.name = name;
    this.to = {
      name: toRouteName,
    };
    this.title = title;
    this.description = description;
    if (iconComponentName) {
      this.iconComponent = getRawAsyncComponent(
        resolveImport(iconComponentName)
      );
    }
  }
}

/**
 * Client Notifications
 */

class CreateOfferNannyNotification extends Notification {
  constructor() {
    super({
      name: "create-offer-nanny",
      toRouteName: ROUTE_ACCOUNT_OFFERS_NAME,
      title: "Dodaj ogłoszenie",
      description: "Jest darmowe i szybkie",
      iconComponentName: "NotificationIconCreateOffer.vue",
    });
  }
}

class AddPhotoToOfferNannyNotification extends Notification {
  constructor() {
    super({
      name: "add-photo-to-offer-nanny",
      // TODO: change route to Update
      toRouteName: ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME,
      title: "Dodaj zdjęcie",
      description: `Ogłoszenia ze zdjęciem 
                    dostają średnio 81% więcej odpowiedzi`,
      iconComponentName: "NotificationIconAddPhoto.vue",
    });
  }
}

class BuyPremiumNotification extends Notification {
  constructor() {
    super({
      name: "buy-premium",
      toRouteName: ROUTE_ACCOUNT_PREMIUM_NAME,
      title: "Zostań Premium",
      description: "Ciesz się poszukiwaniem bez granic",
      iconComponentName: "NotificationIconBuyPremium.vue",
    });
  }
}

/**
 * Contractor Notifications
 */

class CreateApplicationNannyNotification extends Notification {
  constructor() {
    super({
      name: "create-application-nanny",
      toRouteName: ROUTE_ACCOUNT_APPLICATIONS_NAME,
      title: "Wypełnij profil",
      description: "Szybko i łatwo zrobisz to w 2 minuty!",
      iconComponentName: "NotificationIconCreateOffer.vue",
    });
  }
}

class AddPhotoToApplicationNannyNotification extends Notification {
  constructor() {
    super({
      name: "add-photo-to-application-nanny",
      // TODO: change route to Update
      toRouteName: ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_NAME,
      title: "Dodaj zdjęcie",
      description: `Ankiety ze zdjęciem 
                    dostają średnio 81% więcej telefonów`,
      iconComponentName: "NotificationIconAddPhoto.vue",
    });
  }
}

class AddRecommendationsToApplicationNannyNotification extends Notification {
  constructor() {
    super({
      name: "add-recommendations-to-application-nanny",
      // TODO: change route to Update
      toRouteName: ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_NAME,
      title: "Dodaj rekomedacje",
      description: `Opinie o Twojej pracy 
                    mają pozytywny wplyw na zaufanie`,
      iconComponentName: "NotificationIconAddRecommendations.vue",
    });
  }
}

export default function useNotifications() {
  const userStore = useUserStore();

  const notificationsClient = computed(() => {
    const notifications = [];

    if (userStore.offers.nanny_ids.length === 0) {
      notifications.push(new CreateOfferNannyNotification());
    }

    if (userStore.offers.nanny_ids_populated.length > 0) {
      userStore.offers.nanny_ids_populated.forEach((offerNanny) => {
        if (!offerNanny.details.photoMain) {
          notifications.push(new AddPhotoToOfferNannyNotification());
        }
      });
    }

    // TODO!!!!!!! PREMIUM SUCKS HERE
    // TODO: add userStore.premium (or smth) to condition
    if (userStore.offers.nanny_ids.length > 0) {
      notifications.push(new BuyPremiumNotification());
    }

    return notifications;
  });

  const notificationsContractor = computed(() => {
    const notifications = [];

    if (!userStore.applications.nanny_id) {
      notifications.push(new CreateApplicationNannyNotification());
    }

    if (
      userStore.applications.nanny_id_populated &&
      !userStore.applications.nanny_id_populated.details.photoMain
    ) {
      notifications.push(new AddPhotoToApplicationNannyNotification());
    }

    if (
      userStore.applications.nanny_id_populated &&
      userStore.applications.nanny_id_populated.recommendation_ids.length === 0
    ) {
      notifications.push(
        new AddRecommendationsToApplicationNannyNotification()
      );
    }

    return notifications;
  });

  const notifications = computed(() => {
    const userStore = useUserStore();

    if (userStore.userType === "client") {
      return notificationsClient.value;
    }

    if (userStore.userType === "contractor") {
      return notificationsContractor.value;
    }

    return [];
  });

  const badgeNumber = computed(() => {
    return notifications.value.length;
  });

  return { notifications, badgeNumber };
}
