<template>
  <ButtonLink
    v-if="type === 'link' || (type === 'cta' && isActive)"
    :to="to"
    :label="label"
    class="link nav-header__link"
  />
  <ButtonCta
    v-else-if="type === 'cta' && !isActive"
    :to="to"
    :label="label"
    :size="'x-small'"
    class="link nav-header__link"
  />
  <div v-else-if="type === 'notification'" class="notification-link">
    <ButtonLink :to="to" :label="label" class="link nav-header__link" />
    <div
      v-if="to.name === ROUTE_NOTIFICATIONS_NAME && notificationsAmount > 0"
      class="notification-link__badge"
    >
      {{ notificationsAmount }}
    </div>
    <div
      v-else-if="
        to.name === ROUTE_ACCOUNT_RESPONSES_NAME &&
        unseenResponsesAmount > 0 &&
        userType === 'client'
      "
      class="notification-link__badge"
    >
      {{ unseenResponsesAmount }}
    </div>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import useNotifications from "@/composables/useNotifications";
import {
  ROUTE_ACCOUNT_RESPONSES_NAME,
  ROUTE_NOTIFICATIONS_NAME,
} from "@/router/constants";
import { computed } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  components: { ButtonLink, ButtonCta },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    label: String,
    type: {
      default: "link",
      validator: (value) => ["notification", "cta", "link"].includes(value),
    },
  },

  setup() {
    const { badgeNumber: notificationsAmount } = useNotifications();

    const userStore = useUserStore();
    const { userType } = storeToRefs(userStore);

    const unseenResponsesAmount = computed(() => {
      const userOffers = userStore.offers.nanny_ids_populated;
      const unseenResponses = userOffers.flatMap((someOffer) => {
        return someOffer.response_ids_populated.filter(
          (someOfferResponse) => someOfferResponse.isSeen === false
        );
      });
      return unseenResponses.length;
    });

    return {
      notificationsAmount,
      unseenResponsesAmount,
      userType,

      ROUTE_NOTIFICATIONS_NAME,
      ROUTE_ACCOUNT_RESPONSES_NAME,
    };
  },
};
</script>

<style scoped>
.link.nav-header__link {
  font-weight: 300;
  /* opacity: 0.75; */
}
.nav-header__link.router-link-active {
  font-weight: 500;
  text-decoration: var(--button-link-hover-text-decoration);
  /* opacity: 1; */
}
.notification-link {
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.notification-link__badge {
  display: flex;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ff6600;
  font: 400 11px "K2D";
  color: #ffffff;
  line-height: 17px;
}
</style>
