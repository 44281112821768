<template>
  <component v-if="component" :is="component" />
</template>

<script>
import { computed, defineAsyncComponent } from "vue";

import useAuth from "@/composables/useAuth";

export default {
  setup() {
    const { isAuth } = useAuth();

    const component = computed(() => {
      if (isAuth.value) {
        return defineAsyncComponent(() =>
          import(
            "@/components/Header/HeaderDesktopDefaultContainerRightDefault.vue"
          )
        );
      }

      return defineAsyncComponent(() =>
        import(
          "@/components/Header/HeaderDesktopDefaultContainerRightAnonymus.vue"
        )
      );
    });

    return {
      component,
    };
  },
};
</script>
