<template>
  <header class="header">
    <div class="header-content">
      <RouterLink
        :to="{ name: ROUTE_LIST_NANNY_NAME }"
        class="logo header__logo"
      >
        <SvgLogoFull />
      </RouterLink>
      <HeaderDesktopDefaultContainerMiddleDefault v-if="isAuth" />
      <HeaderDesktopDefaultContainerMiddleAnonymus v-else />
      <HeaderDesktopDefaultContainerRight />
    </div>
  </header>
</template>

<script>
import { ROUTE_LIST_NANNY_NAME } from "@/router/constants";

import useAuth from "@/composables/useAuth";

import SvgLogoFull from "@/components/Svg/Logo/SvgLogoFull.vue";
import HeaderDesktopDefaultContainerMiddleDefault from "@/components/Header/HeaderDesktopDefaultContainerMiddleDefault.vue";
import HeaderDesktopDefaultContainerRight from "@/components/Header/HeaderDesktopDefaultContainerRight.vue";
import HeaderDesktopDefaultContainerMiddleAnonymus from "@/components/Header/HeaderDesktopDefaultContainerMiddleAnonymus.vue";

export default {
  components: {
    SvgLogoFull,
    HeaderDesktopDefaultContainerMiddleDefault,
    HeaderDesktopDefaultContainerRight,
    HeaderDesktopDefaultContainerMiddleAnonymus,
  },

  setup() {
    const { isAuth } = useAuth();

    return {
      isAuth,
      ROUTE_LIST_NANNY_NAME,
    };
  },
};
</script>

<style scoped>
.header {
  position: sticky;
  top: 0px;
  z-index: 9999999999999999999;

  width: 100vw;
  height: var(--header-default--height);

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--main-bg-color);
}
.header-content {
  width: var(--header-default--width);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
